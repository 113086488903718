import { RoleTypes } from './role-types.enum';

export class User {
	id: number;
	name: string;
	last_name: string;
	age: number;
	email: string;
	password: string;
	salt: string;
	phone: string;
	cel: string;
	country: string;
	state: string;
	photo?: string;
	srPagoId?: string;
	status: number;
	active: number;
	session_price?: number;
	discount_percent?: number;
	twilio_sid?: string;
	channels?: any[];
	channel?: any;
	created_at: Date;
	uuid?: string;
	roleId?: RoleTypes;
	role?: {
		id: number;
		name: string;
		description: string;
	};
	// therapistId?: number;
	// supportId?: number;
	emergency_contact?: string;
	emergency_relation?: string;
	emergency_phone?: string;
	birthdate: Date | string;
	// enterpriseId?: number;
	manager: number;
	// positionId?: number;
	// jobId?: number;
	// workdayId?: number;
	test: number;
	paymentId?: string;
	detail?: any;
	therapist?: any;

	// OPTIONAL PARAMS
	lastMessage?: string;

	constructor() {
		this.id = 0;
		this.name = '';
		this.last_name = '';
		this.age = 0;
		this.email = '';
		this.password = '';
		this.salt = '';
		this.phone = '';
		this.cel = '';
		this.country = '';
		this.state = '';
		this.photo = '';
		this.srPagoId = '';
		this.status = 0;
		this.active = 0;
		this.session_price = 0;
		this.discount_percent = 0;
		this.twilio_sid = '';
		this.channels = [];
		this.created_at = new Date();
		this.uuid = '';
		this.roleId = RoleTypes.NONE;
		this.role = { name: '', description: '', id: 0 };
		// this.therapistId = 0;
		// this.supportId = 0;
		this.emergency_contact = '';
		this.emergency_relation = '';
		this.emergency_phone = '';
		this.birthdate = new Date();
		// this.enterpriseId = 0;
		this.manager = 0;
		// this.positionId = 0;
		// this.jobId = 0;
		// this.workdayId = 0;
		this.test = 0;
		this.paymentId = '';
		this.detail = {};
		this.therapist = {};
	}

	get full_name() {
		return `${this.name} ${this.last_name}`;
	}

	// Support user only need id 8
	static createSupportUser(): User {
		const user = new User();
		user.id = 8;
		user.name = 'Soporte';
		user.last_name = 'Mind2';
		user.email = 'soporte@mind2.me';

		return user;
	}

	static fromJson(data: any) {
		const user = new User();
		user.id = data.id;
		user.name = data.name;
		user.last_name = data.last_name;
		user.age = data.age;
		user.email = data.email;
		user.password = data.password;
		user.salt = data.salt;
		user.phone = data.phone;
		user.cel = data.cel;
		user.country = data.country;
		user.state = data.state;
		user.photo = data.photo;
		user.srPagoId = data.srPagoId;
		user.status = data.status;
		user.active = data.active;
		user.session_price = data.session_price;
		user.discount_percent = data.discount_percent;
		user.twilio_sid = data.twilio_sid;
		user.channels = data.channels;
		user.created_at = data.created_at;
		user.uuid = data.uuid;
		user.roleId = data.roleId ?? data.role.id ?? RoleTypes.NONE;
		user.role = data.role ?? { name: '', description: '', id: 0 };
		// user.therapistId = data.therapistId;
		// user.supportId = data.supportId;
		user.emergency_contact = data.emergency_contact;
		user.emergency_relation = data.emergency_relation;
		user.emergency_phone = data.emergency_phone;
		user.birthdate = data.birthdate;
		// user.enterpriseId = data.enterpriseId;
		user.manager = data.manager;
		// user.positionId = data.positionId;
		// user.jobId = data.jobId;
		// user.workdayId = data.workdayId;
		user.test = data.test;
		user.paymentId = data.paymentId;
		user.detail = data.detail;
		user.therapist = data.therapist;
		return user;
	}
}
