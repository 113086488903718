import { User } from './user.model';

export class Session {
	accessToken: string;
	userId: number;
	user: User;

	constructor() {
		this.accessToken = '';
		this.userId = 0;
		this.user = new User();
	}

	static fromJson(data: any) {
		if (!data) return;

		const session = new Session();
		session.accessToken = data.accessToken;
		session.userId = data.userId;
		session.user = User.fromJson(data.user);
		return session;
	}
}
