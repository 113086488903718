import { Injectable } from '@angular/core';
import { Session } from '@models/session.model';

@Injectable({
	providedIn: 'root',
})
export class StorageService {
	constructor() {}

	/**
	 * SESSION
	 */
	saveSession(data: Session) {
		localStorage.setItem('session', JSON.stringify(data));
	}
	getSession(): Session {
		return Session.fromJson(JSON.parse(localStorage.getItem('session')));
	}
	deleteSession() {
		localStorage.removeItem('session');
	}

	/**
	 * TWILIO
	 */
	saveTwilioChatToken(token: string) {
		localStorage.setItem('twilioChatToken', token);
	}
	getTwilioChatToken(): string {
		return localStorage.getItem('twilioChatToken');
	}
	deleteTwilioChatToken() {
		localStorage.removeItem('twilioChatToken');
	}

	saveTwilioVideoAccess(access: { token: string; room: string }) {
		localStorage.setItem('twilioVideoAccess', JSON.stringify(access));
	}
	getTwilioVideoAccess(): { token: string; room: string } {
		return JSON.parse(localStorage.getItem('twilioVideoAccess'));
	}

	/**
	 * Menu Selection
	 */

	saveMenuSelection(data: string) {
		localStorage.setItem('menuSelection', data);
	}
	getMenuSelection(): string {
		return localStorage.getItem('menuSelection');
	}

	/**
	 * General
	 */

	saveData(name: string, data: any) {
		localStorage.setItem(name, JSON.stringify(data));
	}
	getData(name: string): any {
		return JSON.parse(localStorage.getItem(name));
	}
	deleteData(name: string) {
		localStorage.removeItem(name);
	}
}
